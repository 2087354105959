import React from "react";

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/styles/variables/colors.css";
import "./src/styles/variables/general.css";
import "./src/styles/variables/sizes.css";
import "./src/styles/variables/type.css";
import "./src/styles/global.scss";

import "typeface-ibm-plex-sans";
import "typeface-ibm-plex-mono";

import Layout from "./src/components/layout/Layout";

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};
