import React from "react";
import { PageContainer } from "../../common/PageContainer";
import * as styles from "./Footer.module.scss";

const Footer = ({ className, location }) => {
  return (
    <footer className={styles.footer}>
      <PageContainer>amcc.io</PageContainer>
    </footer>
  );
};

export { Footer };
