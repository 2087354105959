import React from "react";
import * as styles from "./PageContainer.module.scss";

const PageContainer = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.whatever}>{children}</div>
    </div>
  );
};

export { PageContainer };
