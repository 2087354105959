import React from "react";
import { Link } from "gatsby";

import * as styles from "./Header.module.scss";

const Header = ({ className, location }) => {
  const link = location.pathname.split("/")[1];

  return (
    <header>
      <nav aria-labelledby="mainmenulabel" className={styles.nav}>
        <Link to="/">
          <h2 id="mainmenulabel">amcc.io</h2>
        </Link>
        <span className={styles.slash}>/</span>
        <div className={styles.breakFlex} />
        <Link to="/" activeClassName="active">
          <h1>lab</h1>
        </Link>
        <span className={styles.slash}>/</span>
        <div className={styles.breakFlex} />
        <Link to="/projects" activeClassName="active">
          <h1>work</h1>
        </Link>
        <span className={styles.slash}>/</span>
        <div className={styles.breakFlex} />
        <Link to="/about" activeClassName="active">
          <h1>about</h1>
        </Link>
      </nav>
    </header>
  );
};

export { Header };
